import https from "./https"
import store from "@/store"

const crm = {
  async staffBinding(staffId) {
    const { data } = await https.post(`${store.state.liffGeneral.orgCode}/liff/crm/staff-binding`, { staff_id: staffId })
    return data.data
  },

  async customerBinding(customerId) {
    const { data } = await https.post(`${store.state.liffGeneral.orgCode}/liff/crm/customer-binding`, { customer_id: customerId })
    return data.data
  },
}

export default crm
