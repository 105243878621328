<template>
  <div>
    <div v-if="showLoading"><Loading /></div>
    <img v-if="getModuleConfig('page_kv_image')" :src="getModuleConfig('page_kv_image')" class="w-100" />
    <div class="page">
      <div class="page__title">
        {{ getModuleConfig(`${pageKey}.form_title`) }}
      </div>
      <div class="page__desc">
        {{ getModuleConfig(`${pageKey}.form_desc`) }}
        <div v-if="errorHtml" v-html="errorHtml"></div>
      </div>
      <b-card class="mt-3" :img-src="avatarUrl" img-alt="Image" img-top>
        <div>
          {{ lineData.displayName }}
        </div>
      </b-card>
    </div>
    <div class="page__button">
      <MemberButton
        :button-text="getModuleConfig(`${pageKey}.button_text`)"
        :button-url="getModuleConfig(`${pageKey}.button_url`)"
        :type="getModuleConfig(`${pageKey}.button_action`)"
      ></MemberButton>
      <SharedButton
        v-if="showRedirect"
        class="s-btn-bg-primary"
        type="button"
        @click="redirect"
      >
        {{ redirectText }}
      </SharedButton>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Page/Liff/Shared/Loading";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import MemberButton from "@/components/Page/Liff/Shared/MemberButton";
import liff from "@line/liff";
import crmApi from "@/apis/liff/v2/crm";
const MODULE_CODE = "member_bind";

export default {
  components: { Loading, SharedButton, MemberButton },
  data() {
    return {
      showLoading: true,
      customerId: null,
      redirectUri: null,
      redirectText: null,
      lineData: {},
      token: null,
      pageKey: null,
      errorHtml: null,
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    avatarUrl() {
      return (
        this.lineData.pictureUrl ??
        "https://via.placeholder.com/500x500.png?text=Empty"
      );
    },
    showRedirect() {
      return  this.redirectUri && this.redirectText && this.pageKey == 'welcome_page';
    },
  },
  methods: {
    async init() {
      this.lineData = await liff.getProfile();
      this.token = await liff.getAccessToken();
      this.customerId = this.$route.query.customer_id;
      this.redirectUri = this.$route.query.redirect_uri;
      this.redirectText = this.$route.query.redirect_text;

      await this.submit();
    },
    async submit() {
      crmApi.customerBinding(this.customerId)
        .then(() => {
          this.showLoading = false;
          this.pageKey = "welcome_page"
        })
        .catch((result) => {
          this.showLoading = false;
          this.pageKey = "failed_page"

          let errorHtml = result.response.data.error || result.response.data.message || result.response.message
          errorHtml = errorHtml.replace(/(\r\n|\n|\r)/g, "<br/>")
          this.errorHtml = errorHtml
        });
    },
    redirect() {
      window.location.href = this.redirectUri;
    },
    getModuleConfig(key) {
      return this.$store.getters["liffModule/getConfig"](MODULE_CODE, key);
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .card {
  width: 250px;
  margin: auto;
}
</style>
